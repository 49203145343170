.buzz--article.first {
  -skrollr-animation-name: articleFirst; }

@-skrollr-keyframes articleFirst {
  bottom-top {
    opacity: 0;
    transform: translateX(60px); }

  bottom-bottom {
    opacity: 1;
    transform: translateX(0px); } }

.buzz--article.middle {
  -skrollr-animation-name: articleMiddle; }

@-skrollr-keyframes articleMiddle {
  100-bottom-top {
    opacity: 0;
    transform: translateX(-80px); }

  bottom-bottom {
    opacity: 1;
    transform: translateX(0px); } }

.buzz--article.last {
  -skrollr-animation-name: articleLast; }

@-skrollr-keyframes articleLast {
  bottom-top {
    opacity: 0;
    transform: translateX(60px); }

  bottom-bottom {
    opacity: 1;
    transform: translateX(0px); } }

